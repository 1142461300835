import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { FormGroup } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class EcommerceService {
  private url = 'https://amigosbooks.in/php_restapi_v1.php';
  private url_get = 'https://amigosbooks.in/schollcode_save.php';
  private subjectName = new Subject<any>();
  private LoggedIn = new Subject<any>();
  constructor(private http: HttpClient, private dialog: MatDialog) { }
  // getProducts(): Observable<any> {
  //   return this.http.get<any>(`${this.url + '?option=products'}`, {
  //     headers: new HttpHeaders({ 'content-Type': 'application/json' })
  //   });
  // }
  getProductsbySchoolCode(data): Observable<any> {
    debugger
    return this.http.post<any>(`${this.url + '?option=getProductsbySchoolCode'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }

  getSimpleProducts(): Observable<any> {
    return this.http.get<any>(`${this.url + '?option=simpleproducts'}`, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  getClass(): Observable<any> {
    return this.http.get<any>(`${this.url + '?option=class'}`, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  getSchoolList(): Observable<any> {
    return this.http.get<any>(`${this.url + '?option=schoolcods'}`, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  // checkOut(data): Observable<any> {
  //   
  //   return this.http.post<any>(`${this.url_get + '?option=customercheckout'}`, data, {
  //     headers: new HttpHeaders({ 'content-Type': 'application/json' })
  //   });
  // }
  // ccAvenuePayment_Display(data): Observable<any> {
  //    return this.http.post<any>(`${this.url_get + '?option=ccAvenuePayment'}`, data, {
  //     headers: new HttpHeaders({ 'content-Type': 'application/json' })
  //   });
  // }
  ccAvenuePayment(data): Observable<any> {

    return this.http.post<any>(`${this.url_get + '?option=ccAvenuePayment'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  getOrderandInvoice_detaildforEcommerce(data: any): Observable<any> {

    return this.http.post<any>(`${this.url_get + '?option=getOrderandInvoice_detaildforEcommerce'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  getEcommerecInvoice(data): Observable<any> {
    return this.http.post<any>(`${this.url_get + '?option=getinvoiceEcommerce'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  getEcommerecInvoice_TOTAL(data): Observable<any> {
    return this.http.post<any>(`${this.url_get + '?option=getinvoiceEcommerce_TOTAL'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  getinvoiceCustomerEcommerce(data): Observable<any> {
    debugger
    return this.http.post<any>(`${this.url_get + '?option=getinvoiceCustomerEcommerce'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  getOrderLiveStatusforCustomer(data): Observable<any> {

    return this.http.post<any>(`${this.url_get + '?option=getOrderLiveStatusforCustomer'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  fnUpdatePaymentStatus(data): Observable<any> {
    return this.http.post<any>(`${this.url_get + '?option=UpdatePaymentStatus'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  getShippingAddress(data): Observable<any> {
    return this.http.post<any>(`${this.url_get + '?option=getshipping'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  getAddressFromShipping(data): Observable<any> {

    return this.http.post<any>(`${this.url_get + '?option=getaddressfromshipping'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  getOrdersdta(data): Observable<any> {
    return this.http.post<any>(`${this.url + '?option=getorders'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  getOrdersdtaCount(): Observable<any> {
    return this.http.post<any>(`${this.url + '?option=getorderscount'}`, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  getCustomersdtaCount(data): Observable<any> {
    debugger
    return this.http.post<any>(`${this.url + '?option=getcustomerscount'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }

  regiterUser(data): Observable<any> {
    sessionStorage.setItem('customerresgiter', JSON.stringify(data));
    sessionStorage.setItem('update_password_otp', JSON.stringify(data));
    localStorage.setItem('customerautofill', JSON.stringify(data));
    return this.http.post<any>(`${this.url_get + '?option=registeruser'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  updatePass(data): Observable<any> {

    return this.http.post<any>(`${this.url_get + '?option=updatepass'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  updateAddress(data): Observable<any> {

    return this.http.post<any>(`${this.url_get + '?option=updateaddress'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  loginUser(data): Observable<any> {
    return this.http.post<any>(`${this.url_get + '?option=loginuser'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  checkuserexist(data): Observable<any> {
    return this.http.post<any>(`${this.url_get + '?option=checkloginuser'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }

  AddProductTocart(product: any) {
    product.quantity = 1;
    let AddedProducts = [];
    if (sessionStorage.getItem('CartProducts')) {
      AddedProducts = JSON.parse(sessionStorage.getItem('CartProducts'));
    }
    if (AddedProducts.filter(i => i.id === product.id).length === 0) {
      AddedProducts.push(product);
      sessionStorage.setItem('CartProducts', JSON.stringify(AddedProducts));
    }
    this.sendUpdate();
  }
  GetAddedCart() {
    let AddedProducts = [];
    if (sessionStorage.getItem('CartProducts')) {
      AddedProducts = JSON.parse(sessionStorage.getItem('CartProducts'));
    }
    return AddedProducts;
  }
  getOrderProductDetails(data: any): Observable<any> {
    debugger
    return this.http.post<any>(`${this.url_get + '?option=orders_product_list'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  AddItemsToCart(item) {
    let AddedProducts = this.GetAddedCart();
    AddedProducts = AddedProducts.filter(i => i.id != item.id);
    AddedProducts.push(item);
    sessionStorage.removeItem('CartProducts');
    sessionStorage.setItem('CartProducts', JSON.stringify(AddedProducts));
  }

  RemoveFromThecart(prodid) {
    let products = [];
    if (sessionStorage.getItem('CartProducts')) {
      products = JSON.parse(sessionStorage.getItem('CartProducts'));
      products = products.filter(i => i.id !== prodid);
      sessionStorage.setItem('CartProducts', JSON.stringify(products));
    }
    return products;
  }

  sendUpdate() {
    this.subjectName.next(this.GetAddedCart());
  }
  SetIsLogin() {
    const isloggedIn: boolean = sessionStorage.getItem('RegUser') ? true : false;
    this.LoggedIn.next(isloggedIn);
  }
  IsLoggedIn(): Observable<boolean> {   // console.log("siva---" + sessionStorage.getItem('RegUser'));

    return this.LoggedIn.asObservable();
  }
  getUpdate(): Observable<any> {
    return this.subjectName.asObservable();
  }
  checkOtponDB(data): Observable<any> {
    debugger
    return this.http.post<any>(`${this.url_get + '?option=checkotp'}`, data, {
      headers: new HttpHeaders({ 'content-Type': 'application/json' })
    });
  }
  fnShowLoginDialog(componentName: any, Formval: FormGroup) {
    const dialogRef = this.dialog.open(componentName);
    dialogRef.afterClosed().subscribe(result => {

      // console.log('The dialog was closed');
      if (result) {
        if (result === 'login') {
          this.fnShowLoginDialog(LoginComponent, Formval);
        } else if (result === 'register') {
          this.fnShowLoginDialog(RegisterComponent, Formval);
        } else if (result === 'forgot') {
          this.fnShowLoginDialog(ForgotpasswordComponent, Formval);
        }
      }
      if (sessionStorage.getItem('RegUser') && sessionStorage.getItem('RegUser') == Formval.controls.altno.value) {
        Formval.controls.altno.setValue('');
      }
      // this.animal = result;
    });
  }

  fnCalculateShippingAmtWithoutDiscount() {
    // Orginal_Shipping_Amout as client added
    const CartItemsList = this.GetAddedCart();
    let totalShippingCharges = 0;
    CartItemsList.forEach((element: any) => {
      totalShippingCharges += Number(element.shipping_amount) * element.quantity;
    });
    return totalShippingCharges;
  }
  fnCalculateShippingAmtWithDiscount() {
    // after dicount shipping amount
    const CartItemsList = this.GetAddedCart();
    let totalShippingCharges = 0;
    CartItemsList.forEach((element: any) => {
      totalShippingCharges += Number(element.shipping_amount) * element.quantity;
    });
    const DiscountAmount = this.fnCalculateDiscountAmt();
    return totalShippingCharges - DiscountAmount;
  }

  fnCalculateDiscountAmt(): number {
    // discount 40
    const CartItemsList = this.GetAddedCart();
    let totalShippingCharges = 0;
    let DiscountAmount = 0;
    let totalItems = 0;
    CartItemsList.forEach((element: any) => {
      totalShippingCharges += Number(element.shipping_amount) * element.quantity;
      totalItems = totalItems + element.quantity;
    });
    if (totalItems > 1) {
      DiscountAmount = (totalShippingCharges) * 40 / 100;
    }
    return DiscountAmount;

  }


}
