import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EcommerceService } from '../ecommerce.service';

@Component({
  selector: 'app-ccavenue-payment',
  templateUrl: './ccavenue-payment.component.html',
  styleUrls: ['./ccavenue-payment.component.css']
})
export class CCAvenuePaymentComponent implements OnInit, AfterViewInit {
  public accessCode: 'AVOQ72JC96AJ50QOJA';
  public encRequestRes: any;
  public iframeUrl = '';
  public customeridnull = true;
  @ViewChild('form', { static: false }) form: ElementRef;
  @ViewChild('iframe', { static: false }) iframe: ElementRef;
  checkoutData: any;
  constructor(public service: EcommerceService, public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.checkoutData = this.data.formobj;
    if (this.checkoutData.order_id.toString().split('-')[0] === 'undefined') {
      this.service.getCustomersdtaCount(sessionStorage.getItem('RegUser')).subscribe(
        (ele) => {
          this.checkoutData.order_id = ele.data[0].id + '-' + this.checkoutData.order_id.toString().split('-')[1];
          // tslint:disable-next-line:radix
          this.CCAvenueAPICAll();
        });
    } else {
      this.CCAvenueAPICAll();
    }


  }
  ngAfterViewInit() {

  }
  CCAvenueAPICAll() {
    if (this.checkoutData.customer_id === null || this.checkoutData.customer_id === undefined || this.checkoutData.customer_id === '') {
      this.customeridnull = true;
    } else {
      this.checkoutData = this.data.formobj;
      const PassingObj = {
        tid: this.checkoutData.tid,
        merchant_id: this.checkoutData.merchant_id,
        order_id: this.checkoutData.order_id,
        gatewaycharges: this.checkoutData.gatewaycharges,
        currency: this.checkoutData.currency,
        redirect_url: this.checkoutData.redirect_url,
        cancel_url: this.checkoutData.cancel_url,
        language: this.checkoutData.language,
        amount: this.checkoutData.amount,  // change amount if required
        Orginal_Shipping_Amout: this.checkoutData.Orginal_Shipping_Amout,  // change amount if required
        After_Dicount_Shipping_Amout: this.checkoutData.After_Dicount_Shipping_Amout,  // change amount if required
        Dicount_Shipping_Amout: this.checkoutData.Dicount_Shipping_Amout,  // change amount if required
        terms: this.checkoutData.terms,  // change amount if required

        billing_name: this.checkoutData.first_name + ', ' + this.checkoutData.first_name,
        billing_address: this.checkoutData.address1 + ', ' + this.checkoutData.address2,
        billing_city: this.checkoutData.city,
        billing_state: this.checkoutData.state,
        billing_zip: this.checkoutData.zipcode,
        billing_country: this.checkoutData.country,
        billing_tel: this.checkoutData.altno,
        billing_email: this.checkoutData.emailid,
        products: this.checkoutData.products,
        customer_id: this.checkoutData.customer_id,
      };
      this.customeridnull = false;
    }
  }
}
