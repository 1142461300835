<button (click)="downloadAsPDF()" style="    background-color: #c82427;
    border: none;
    color: white;
    padding: 6px 13px;
    cursor: pointer;
    font-size: 20px;">Download</button>
<div *ngIf="loadingstatus" style="font-weight: bold; color:blue;">Please Wait Downloading...</div>
<div *ngIf="loadingstatus1" style="font-weight: bold; color:red; font-size: 2em;">Please Wait Data loading...</div>
<mat-dialog-content class="mat-typography">
    <div id="pdfTable" #pdfTable>
        <table align="center" class="main-table" border="0" cellspacing="0" cellpadding="20" style="background-image:url(bg.PNG); background-repeat:no-repeat; background-position:center;width:150mm;">

            <tbody>
                <tr>
                    <td style="padding: 20px;width:200.4mm">
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                            <tbody>
                                <tr>
                                    <td>
                                        <table style="border-collapse: collapse; width: 100.274%;" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 100%; text-align: center;"><img src="assets/image/DeepthiLogo.png"></td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 100%; text-align: center;">
                                                        <p><span style="text-decoration: underline;">Amigos Book Distributors</span></p>
                                                        <p>GSTIN : 36GYIPK3599A1Z5</p>
                                                        <p>Telangana</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 100%; text-align: center;"><span style="text-decoration: underline;">Tax Invoice</span></td>
                                                </tr>
                                                <tr>
                                                    <!-- {{studentandpaymentInfo | json}} dd -->
                                                    <td style="width: 100%; text-align: left;">
                                                        <table style="border-collapse: collapse; width: 100%;" border="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="width: 32.0187%;">Bill # : {{bill_order_number}}</td>
                                                                    <td style="width: 32.0187%;">Date : {{bill_date_paid | date: 'dd/MMM/yyyy'}}</td>
                                                                    <td style="width: 32.0187%;">Time : {{bill_date_paid | date: 'hh:m a'}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 32.0187%;">Customer :{{bill_first_name}} {{bill_last_name}}</td>
                                                                    <td style="width: 32.0187%;">&nbsp;</td>
                                                                    <td style="width: 32.0187%;">Mobile : {{bill_phone}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <!-- <tr>
                                            <td style="width: 100%; text-align: left;">School Name : {{studentandpaymentInfo[0].school_name}}</td>
                                        </tr> -->
                                                <!-- <tr>
                                                <td style="width: 100%; text-align: left;">Class&nbsp; : {{studentandpaymentInfo[0].class}}</td>
                                            </tr> -->
                                                <tr>
                                                    <td style="width: 100%; text-align: center;">
                                                        <hr style="border-top: 1px dashed black;">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table border="0" class="k45 item-head-tab" style="border-collapse: collapse;width: 100%;">
                                            <thead>
                                                <tr class="item-head" style="border: none; background-color: #33ccff;">
                                                    <td class="item-head1" style="font-size: 13px; width: 5%; height: 18px;" align="center" colspan=""><span style="text-decoration: underline;">SNo.</span></td>
                                                    <td class="item-head2" style="font-size: 13px; padding-left: 8px; width: 45%; height: 18px;" align="left" colspan=""><span style="text-decoration: underline;">ITEM NAME</span></td>
                                                    <td class="item-head4" style="font-size: 13px; width: 7%; height: 18px;" align="center" colspan=""><span style="text-decoration: underline;">CGST</span></td>
                                                    <td class="item-head4" style="font-size: 13px; width: 7%; height: 18px;" align="center" colspan=""><span style="text-decoration: underline;">SGST</span></td>
                                                    <td class="item-head3" style="font-size: 13px; width: 7%; height: 18px;" align="center" colspan=""><span style="text-decoration: underline;">QTY</span></td>
                                                    <td class="item-head4" style="font-size: 13px; width: 7%; height: 18px;" align="center" colspan=""><span style="text-decoration: underline;">RATE</span></td>
                                                    <td class="item-head6" style="font-size: 13px; width: 7%; height: 18px;" align="center" colspan=""><span style="text-decoration: underline;">NET AMT </span></td>
                                                </tr>
                                                <tr></tr>
                                            </thead>
                                            <tbody>

                                                <tr class="head-item-details-tr" style="border: none; background-color: #33ccff;" *ngFor="let bag of onlyBags;">
                                                    <td colspan="7"> {{bag}}
                                                        <table *ngFor="let item of fnGetResultBybagName(bag);  index as i;" style="width: 100%;">
                                                            <tr>

                                                                <td class="head-item-details1" style="font-size: 13px; width: 5%; height: 18px;" align="center" colspan="">{{i+1}}</td>
                                                                <td class="head-item-details2" style="font-size: 13px; padding-left: 8px; width: 45%; height: 18px;" align="left" colspan="">{{item.title}}</td>
                                                                <td class="head-item-details4" style="font-size: 13px; width: 7%; height: 18px;" align="center" colspan="">{{item.tax_amount_cgst}}</td>
                                                                <td class="head-item-details4" style="font-size: 13px; width: 7%; height: 18px;" align="center" colspan="">{{item.tax_amount_sgst}}</td>
                                                                <td class="head-item-details3" style="font-size: 13px; width: 7%; height: 18px;" align="center" colspan="">{{item.qty}}</td>
                                                                <td class="head-item-details4" style="font-size: 13px; width: 7%; height: 18px;" align="center" colspan="">{{item.sell_price}}</td>
                                                                <td class="head-item-details6" style="font-size: 13px; width: 7%; height: 18px;" align="center" colspan="">{{item.qty * item.sell_price }}</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>


                                                <tr style="" class="extrarows-inside1"></tr>
                                                <tr style="" class="insidegride-gst1"></tr>
                                                <tr style="" class="insidegride-gst2"></tr>
                                                <tr style="" class="other-chargeee"></tr>
                                                <tr class="head-foot-details"></tr>
                                            </tbody>
                                        </table>
                                    </td>

                                </tr>

                                <tr>
                                    <td>
                                        <table style="border-collapse: collapse; width: 100%;" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 98.6168%; text-align: center;">
                                                        <hr style="border-top: 1px dashed black;">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 98.6168%;">
                                                        <table style="border-collapse: collapse; width: 100%;" border="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="width: 48.654%;"> Qty : {{TQ}}</td>
                                                                    <td style="width: 48.654%; text-align: right;">Total : {{TAT | currency:"INR":"symbol"}}</td>

                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <!-- <tr>
                                                    <td style="width: 98.6168%;">
                                                        <hr style="border-top: 1px dashed black;">
                                                    </td>
                                                </tr> -->


                                                <!-- <tr>
                                                    <td style="width: 98.6168%; text-align: center;">
                                                        <table border="1" class="k46 gst-grid-table-main" style="border-collapse: collapse;width: 100%;">
                                                            <thead class="gst-sum-head1">
                                                                <tr class="gst-sum-head">
                                                                     <td style="width: 15.3614%; text-align: center;" class="tax-det-head2" align="" colspan="">CGST %</td>
                                                                    <td style="width: 15.3614%; text-align: center;" class="tax-det-head3" align="" colspan="">CGST AMT</td>
                                                                    <td style="width: 15.3614%; text-align: center;" class="tax-det-head4" align="" colspan="">SGST %</td>
                                                                    <td style="width: 15.3614%; text-align: center;" class="tax-det-head5" align="" colspan="">SGST AMT</td>
                                                                    <td style="width: 15.3614%; text-align: center;" class="tax-det-head6" align="" colspan="">TAX AMOUNT</td>
                                                                </tr>
                                                                <tr></tr>
                                                            </thead>
                                                            <tbody class="gst-sum-det1">
                                                                <tr class="gst-sum-det" *ngFor="let item of CgstSgst; index as i">
                                                                     <td style="width: 15.3614%; text-align: center;" id="zorotsx{{i}}" class="tax-det-item2" align="" colspan="">{{item.tax_cgst}}%</td>
                                                                    <td style="width: 15.3614%; text-align: center;" id="zorotsx{{i}}" class="tax-det-item3" align="" colspan="">{{item.tax_amount_cgst}}</td>
                                                                    <td style="width: 15.3614%; text-align: center;" id="zorotsx{{i}}" class="tax-det-item4" align="" colspan="">{{item.tax_sgst}}%</td>
                                                                    <td style="width: 15.3614%; text-align: center;" id="zorotsx{{i}}" class="tax-det-item5" align="" colspan="">{{item.tax_amount_sgst}}</td>
                                                                    <td style="width: 15.3614%; text-align: center;" id="zorotsx{{i}}" class="tax-det-item5" align="" colspan="">{{item.tax_amount_sgst }}</td>
                                                                </tr>
                                                                
                                                                <tr class="gst-sum-foot"></tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr> -->
                                                <tr>
                                                    <td style="width: 98.6168%;">
                                                        <hr style="border-top: 1px dashed black;">
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style="width: 98.6168%;">
                                                        <p><span>Terms &amp; Conditions :</span></p>
                                                        <p><span>NET AMOUNT Including CGST and SGST</span></p>
                                                        <p></p>
                                                        <p>GOODS ONCE SOLD NOT BE TAKEN BACK</p>
                                                        <p></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 98.6168%;">
                                                        <hr style="border-top: 1px dashed black;">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 98.6168%; text-align: center;">**Thanks for shopping. Visit Again **</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>

        </table>
    </div>
</mat-dialog-content>