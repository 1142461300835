<!-- footer start -->
<section class="footer-one section-tb-padding">
    <div class="container">
        <div class="row">
            <div class="col">
                <!-- <div class="footer-service section-b-padding">
                    <ul class="service-ul">
                        <li class="service-li">
                            <a href="assets/javascript:void(0)"><i class="fa fa-truck"></i></a>
                            <span>Free delivery</span>
                        </li>
                        <li class="service-li">
                            <a href="assets/javascript:void(0)"><i class="fa fa-rupee"></i></a>
                            <span>Cash on delivery</span>
                        </li>
                        <li class="service-li">
                            <a href="assets/javascript:void(0)"><i class="fa fa-refresh"></i></a>
                            <span>30 Days returns</span>
                        </li>
                        <li class="service-li">
                            <a href="assets/javascript:void(0)"><i class="fa fa-headphones"></i></a>
                            <span>Online support</span>
                        </li>
                    </ul>
                </div> -->
                <div class="f-logo">
                    <ul class="footer-ul">
                        <li class="footer-li footer-logo">
                            <a href="assets/index1.html">
                                <img class="img-fluid" src="assets/image/Amigos_logo-300x160.png" alt="">
                            </a>
                        </li>
                        <!-- <li class="footer-li footer-address">
                            <ul class="f-ul-li-ul">
                                <li class="footer-icon">
                                    <i class="ion-ios-location"></i>
                                </li>
                                <li class="footer-info">
                                    <h6>Visakhapatnam Address</h6>
                                    <span>Selina Avenue.</span>

                                    <span>#26-22-5.
                                     </span>
                                    <span>Chaitanyanagar.</span>
                                    <span> Visakhapatnam.</span>
                                    <span> Zip code : 530026.</span>

                            </ul>
                            </li> -->
                        <li class="footer-li footer-address">
                            <ul class="f-ul-li-ul">
                                <li class="footer-icon">
                                    <i class="ion-ios-location"></i>
                                </li>
                                <li class="footer-info">
                                    <h6>Hyderabad Address</h6>
                                    <span>Boduppal</span>
                                    <span>Hyderabad.</span>
                                    <span> Zip code : 500076.</span>

                            </ul>
                            </li>

                            <li class="footer-li footer-address">
                                <ul class="f-ul-li-ul">
                                    <li class="footer-icon">
                                        <i class="ion-ios-email"></i>
                                    </li>
                                    <li class="footer-info">
                                        <h6>EMAIL:
                                        </h6>
                                        <span>ravideepthivizag@gmail.com
                    </span>
                                        <span>contact@amigosbooks.in</span>
                                    </li>
                                </ul>
                            </li>
                    </ul>
                </div>
                <div class="footer-bottom section-t-padding">
                    <div class="footer-link" id="footer-accordian">
                        <div class="f-link">
                            <h2 class="h-footer">Privacy & terms </h2>
                            <a href="assets/#t-cate" data-bs-toggle="collapse" class="h-footer">
                                <span>Privacy & terms</span>
                                <i class="fa fa-angle-down"></i>
                            </a>
                            <ul class="f-link-ul collapse" id="t-cate" data-bs-parent="#footer-accordian">
                                <li class="f-link-ul-li"><a [routerLink]="['/ShippingPolicy']" routerLinkActive="router-link-active">Shipping Policy</a></li>
                                <li class="f-link-ul-li"><a [routerLink]="['/CancellationPolicy']" routerLinkActive="router-link-active">Cancellation Policy</a></li>
                                <li class="f-link-ul-li"><a [routerLink]="['/PrivacyPolicy']" routerLinkActive="router-link-active">Privacy Policy</a></li>

                            </ul>
                        </div>
                        <div class="f-link">
                            <h2 class="h-footer" style="color:#faf8ed;">Services</h2>
                            <a href="assets/#services" data-bs-toggle="collapse" class="h-footer">
                                <span>Services</span>
                                <i class="fa fa-angle-down"></i>
                            </a>
                            <ul class="f-link-ul collapse" id="services" data-bs-parent="#footer-accordian">
                                <li class="f-link-ul-li"><a [routerLink]="['/contactus']" routerLinkActive="router-link-active">Contact Information</a></li>

                                <li class="f-link-ul-li"><a [routerLink]="['/ReturnPolicy']" routerLinkActive="router-link-active">Return Policy</a></li>
                                <li class="f-link-ul-li"><a [routerLink]="['/RefundPolicy']" routerLinkActive="router-link-active">Refund Policy</a></li>

                            </ul>
                        </div>
                        <div class="f-link">
                            <h2 class="h-footer" style="color:#faf8ed;">Privacy & terms</h2>
                            <a href="assets/#privacy" data-bs-toggle="collapse" class="h-footer">
                                <span>Privacy & terms</span>
                                <i class="fa fa-angle-down"></i>
                            </a>
                            <ul class="f-link-ul collapse" id="privacy" data-bs-parent="#footer-accordian">
                                <!-- <li class="f-link-ul-li"><a [routerLink]="['/DisputeResolution']" routerLinkActive="router-link-active">Dispute Resolution</a></li> -->
                                <li class="f-link-ul-li"><a [routerLink]="['/GeneralTermsandConditions']" routerLinkActive="router-link-active">General Terms and Conditions</a></li>
                                <li class="f-link-ul-li"><a [routerLink]="['/besafe']" routerLinkActive="router-link-active">Be Safe</a></li>
                            </ul>
                        </div>
                        <div class="f-link">
                            <h2 class="h-footer">My account</h2>
                            <a href="assets/#account" data-bs-toggle="collapse" class="h-footer">
                                <span>My account</span>
                                <i class="fa fa-angle-down"></i>
                            </a>
                            <ul class="f-link-ul collapse" id="account" data-bs-parent="#footer-accordian">
                                <li class="f-link-ul-li"><a [routerLink]="['/myaccount']" routerLinkActive="router-link-active">My account</a></li>
                                <li class="f-link-ul-li"><a [routerLink]="['/cart']" routerLinkActive="router-link-active">My cart</a></li>
                                <li class="f-link-ul-li"><a [routerLink]="['/checkout']" routerLinkActive="router-link-active">My Checkout</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- footer end -->
<!-- footer copyright start -->
<section class="footer-copyright">
    <div class="container">
        <div class="row">
            <div class="col">
                <ul class="f-bottom">
                    <li class="f-c f-copyright">
                        <p> Amigos Book Distributors © 2020-2021 All rights reserved.
                        </p>
                    </li>
                    <!-- <li class="f-c f-social">
                        <a href="assets/https://www.whatsapp.com/" class="f-icn-link"><i class="fa fa-whatsapp"></i></a>
                        <a href="assets/https://www.facebook.com/" class="f-icn-link"><i class="fa fa-facebook-f"></i></a>
                        <a href="assets/https://twitter.com/" class="f-icn-link"><i class="fa fa-twitter"></i></a>
                        <a href="assets/https://www.instagram.com/" class="f-icn-link"><i class="fa fa-instagram"></i></a>
                        <a href="assets/https://www.pinterest.com/" class="f-icn-link"><i class="fa fa-pinterest-p"></i></a>
                        <a href="assets/https://www.youtube.com/" class="f-icn-link"><i class="fa fa-youtube"></i></a>
                    </li> -->
                    <li class="f-c f-payment">
                        <a href="assets/checkout-1.html"><img src="assets/image/payment.png" class="img-fluid" alt="payment image"></a>
                    </li>
                    <li class="f-c f-copyright">
                        <strong class="text-danger">Website designed & developed by: Amigos Books International Pvt.Ltd
                        </strong>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!-- footer copyright end -->

<!-- back to top start -->
<a href="assets/javascript:void(0)" class="scroll" id="top">
    <span><i class="fa fa-angle-double-up"></i></span>
</a>
<!-- back to top end -->
<div class="mm-fullscreen-bg"></div>