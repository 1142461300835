<!-- top notificationbar start -->
<section class="top1">
  <div class="container">
    <div class="row">
      <div class="col">
        <ul class="top-home">
          <li class="top-home-li"></li>
          <li class="top-home-li t-content">
            <!-- offer text start -->
            <div class="top-content">
              <p class="top-slogn">
                <span
                  ><img
                    src="https://static.whatsapp.net/rsrc.php/ym/r/36B424nhiL4.svg"
                    style="height: 20px"
                    alt=""
                  />
                  us on</span
                >
                <span class="top-c"> 7995551102/03 </span>
              </p>
            </div>
            <!-- offer text end -->
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- top notificationbar end -->
<!-- header start -->
<header class="header-area">
  <div class="header-main-area">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="header-main">
            <!-- logo start -->
            <div class="header-element logo">
              <a [routerLink]="['/home']" routerLinkActive="router-link-active">
                <img
                  src="assets/image/logo1.png"
                  alt="logo-image"
                  class="img-fluid"
                />
              </a>
            </div>
            <!-- logo end -->
            <!-- search start -->
            <div class="header-element search-wrap">
              <input
                type="text"
                name="search"
                placeholder="Search product."
                style="display: none"
              />
              <a href="search.html" class="search-btn"
                ><i class="fa fa-search"></i
              ></a>
            </div>
            <!-- search end -->
            <!-- header-icon start -->
            <div class="header-element right-block-box">
              <ul class="shop-element">
                <li class="side-wrap user-wrap">
                  <div class="acc-desk">
                    <div class="user-icon">
                      <a [routerLink]="['/myaccount']" class="user-icon-desk">
                        <span><i class="icon-user"></i></span>
                      </a>
                    </div>
                    <div
                      class="user-info"
                      *ngIf="checksessioneg == true"
                      style="cursor: pointer"
                    >
                      <span class="acc-title" [routerLink]="['/myaccount']"
                        >My Account</span
                      >
                      {{ LoggedUserName }}<br />
                      <div
                        style="color: red"
                        [routerLink]="['/login']"
                        routerLinkActive="router-link-active"
                      >
                        Logout
                      </div>
                    </div>
                    <div class="user-info" *ngIf="checksessioneg == false">
                      <span class="acc-title">Account</span>
                      <div class="account-login">
                        <a
                          [routerLink]="['/register']"
                          routerLinkActive="router-link-active"
                          style="
                            color: red;
                            animation: blinker 0.7s infinite;
                            animation-duration: 0.7s;
                            animation-timing-function: ease-out;
                            animation-delay: 0s;
                            animation-iteration-count: infinite;
                            animation-direction: normal;
                            animation-fill-mode: none;
                            animation-play-state: running;
                            animation-name: blinker;
                            font-size: 1.2em;
                            font-weight: bold;
                          "
                          >Register</a
                        >
                        <a
                          [routerLink]="['/login']"
                          routerLinkActive="router-link-active"
                          style="font-size: 1.2em; font-weight: bold"
                          >Log in</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="acc-mob">
                    <div
                      class="user-info"
                      *ngIf="checksessioneg == true"
                      style="cursor: pointer"
                    >
                      <span
                        class="acc-title"
                        [routerLink]="['/myaccount']"
                        style="color: #f5ab1e"
                        >My Account</span
                      ><br />
                      {{ LoggedUserName }}<br />
                      <div
                        style="color: red"
                        [routerLink]="['/login']"
                        routerLinkActive="router-link-active"
                      >
                        Logout
                      </div>
                    </div>
                    <div class="user-info" *ngIf="checksessioneg == false">
                      <span class="acc-title">Account</span>
                      <div class="account-login">
                        <a
                          [routerLink]="['/register']"
                          routerLinkActive="router-link-active"
                          style="
                            color: red;
                            animation: blinker 0.7s infinite;
                            animation-duration: 0.7s;
                            animation-timing-function: ease-out;
                            animation-delay: 0s;
                            animation-iteration-count: infinite;
                            animation-direction: normal;
                            animation-fill-mode: none;
                            animation-play-state: running;
                            animation-name: blinker;
                          "
                          >Register</a
                        >/
                        <a
                          [routerLink]="['/login']"
                          routerLinkActive="router-link-active"
                          >Log in</a
                        >
                      </div>
                    </div>
                  </div>
                </li>
                <!-- <li class="side-wrap wishlist-wrap">
                    <a href="wishlist.html" class="header-wishlist">
                      <span class="wishlist-icon"><i class="icon-heart"></i></span>
                      <span class="wishlist-counter">0</span>
                    </a>
                  </li> -->

                <li class="side-wrap cart-wrap">
                  <div
                    class="shopping-widget"
                    [hidden]="CartItemsList.length == 0"
                  >
                    <div class="shopping-cart" (click)="funLoaCartItemsSide()">
                      <a href="javascript:void(0)" class="cart-count">
                        <span class="cart-icon-wrap">
                          <span class="cart-icon"
                            ><i class="icon-handbag"></i
                          ></span>
                          <span id="cart-total" class="bigcounter">{{
                            CartItemsList ? CartItemsList.length : 0
                          }}</span>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div
                    class="shopping-widget"
                    [hidden]="CartItemsList.length > 0"
                  >
                    <div class="shopping-cart" (click)="funLoaCartItemsSide()">
                      <div class="cart-count">
                        <span class="cart-icon-wrap">
                          <span class="cart-icon"
                            ><i class="icon-handbag"></i
                          ></span>
                          <span id="cart-total" class="bigcounter">{{
                            CartItemsList ? CartItemsList.length : 0
                          }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="side-wrap nav-toggler">
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarContent"
                  >
                    <span class="line"></span>
                  </button>
                </li>
              </ul>
            </div>
            <!-- header-icon end -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="header-bottom-area">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="main-menu-area">
            <div class="main-navigation navbar-expand-xl">
              <div class="box-header menu-close">
                <button class="close-box" type="button">
                  <i class="ion-close-round"></i>
                </button>
              </div>
              <!-- menu start -->
              <div class="navbar-collapse" id="navbarContent">
                <div class="megamenu-content">
                  <div class="mainwrap">
                    <ul class="main-menu">
                      <li class="menu-link parent">
                        <a
                          routerLink="/"
                          routerLinkActive="router-link-active"
                          class="link-title"
                        >
                          <span class="sp-link-title">Home</span>
                        </a>
                        <a
                          routerLink="/"
                          class="close-box menu-close link-title link-title-lg"
                        >
                          <span class="sp-link-title">Home</span>
                          <i class="fa fa-angle-down"></i>
                        </a>
                      </li>
                      <!-- <li class="menu-link parent">
                                                <a [routerLink]="['/besafe']" routerLinkActive="router-link-active" class="link-title">
                                                    <span class="sp-link-title">Be Safe!
                            </span>
                                                </a>
                                                <a [routerLink]="['/besafe']" class="close-box menu-close link-title link-title-lg">
                                                    <span class="sp-link-title">Be Safe! </span>
                                                    <i class="fa fa-angle-down"></i>
                                                </a>
                                            </li> -->
                      <li class="menu-link parent">
                        <a
                          [routerLink]="['/aboutus']"
                          routerLinkActive="router-link-active"
                          class="link-title"
                        >
                          <span class="sp-link-title">About Us </span>
                        </a>
                        <a
                          [routerLink]="['/aboutus']"
                          class="close-box menu-close link-title link-title-lg"
                        >
                          <span class="sp-link-title">About Us</span>
                          <i class="fa fa-angle-down"></i>
                        </a>
                      </li>
                      <!-- <li class="menu-link parent">
                                                <a [routerLink]="['/photogallery']" routerLinkActive="router-link-active" class="link-title">
                                                    <span class="sp-link-title">Photo Gallery
                            </span>
                                                </a>
                                                <a [routerLink]="['/photogallery']" class="close-box menu-close link-title link-title-lg">
                                                    <span class="sp-link-title">Photo Gallery</span>
                                                    <i class="fa fa-angle-down"></i>
                                                </a>
                                            </li> -->
                      <li class="menu-link parent">
                        <a
                          [routerLink]="['/contactus']"
                          routerLinkActive="router-link-active"
                          class="link-title"
                        >
                          <span class="sp-link-title">Contact Us </span>
                        </a>
                        <a
                          [routerLink]="['/contactus']"
                          class="close-box menu-close link-title link-title-lg"
                        >
                          <span class="sp-link-title">Contact Us</span>
                          <i class="fa fa-angle-down"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- menu end -->
              <!-- <div class="img-hotline">
                                <div class="image-line">
                                    <a href="javascript:void(0)"><img src="assets/image/icon_contact.png" class="img-fluid" alt="image-icon"></a>
                                </div>
                                <div class="image-content">
                                    <span class="hot-l">Hotline:</span>
                                    <span>7995551102/03</span>
                                </div>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mini-cart">
    <a class="shopping-cart-close"><i class="ion-close-round"></i></a>
    <div class="cart-item-title">
      <p>
        <span class="cart-count-desc">There are &nbsp; </span>
        <span class="cart-count-item bigcounter">
          {{ CartItemsList ? CartItemsList.length : 0 }}</span
        >
        <span class="cart-count-desc"> &nbsp; Products</span>
      </p>
    </div>
    <ul class="cart-item-loop">
      <li class="cart-item" *ngFor="let item of CartItemsList">
        <div class="cart-img">
          <a>
            <img
              src="https://amigosbooks.in/bag-images/{{ item.images }}.jpg"
              alt="cart-image"
              class="img-fluid"
            />
          </a>
        </div>
        <div class="cart-title">
          <h6>{{ item.name }}</h6>
          <div class="cart-pro-info">
            <div class="cart-qty-price">
              <span class="quantity"
                >{{ item.quantity }} x &#8377;{{
                  item.price | number : "1.2-2"
                }}
              </span>
              <span class="price-box">
                = &#8377;
                {{ item.price * item.quantity | number : "1.2-2" }} INR</span
              >
            </div>
            <div class="delete-item-cart">
              <a
                ><i
                  class="icon-trash icons"
                  (click)="removeFromcart(item.id)"
                ></i
              ></a>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <ul class="subtotal-title-area">
      <li class="subtotal-info">
        <div class="subtotal-titles">
          <h6>
            <div>
              Subtotal:
              <span style="font-size: 10px">
                (Includes CGST {{ getCGSTTotal() | number : "1.2-2" }} and SGST
                {{ getSGSTTotal() | number : "1.2-2" }} )</span
              >
            </div>
          </h6>
          <span class="subtotal-price"
            >&#8377; {{ getTotal() | number : "1.2-2" }} INR</span
          >
        </div>
      </li>
      <li class="subtotal-info">
        <div class="subtotal-titles">
          <h6>Shipping and Handling</h6>
          <span class="subtotal-price"
            >&#8377; {{ getShippingTotal() | number : "1.2-2" }} INR</span
          >
        </div>
      </li>
      <li class="mini-cart-btns">
        <div class="cart-btns">
          <!-- <a [routerLink]="['/cart']" routerLinkActive="router-link-active" class="btn btn-style2">View cart</a>
                    <a [routerLink]="['/checkout']" routerLinkActive="router-link-active" class="btn btn-style2">checkout</a> -->
          <a
            href="#/cart"
            style="position: static; padding: 10px"
            class="btn btn-style2 shopping-cart-close"
            style="position: static"
            >View cart</a
          >
          <a
            href="#/checkout"
            style="position: static; padding: 10px"
            class="btn btn-style2 shopping-cart-close"
            >checkout</a
          >
        </div>
      </li>
    </ul>
  </div>
</header>
<!-- header end -->
