<div style="width: 90%; margin: 0px auto; padding: 2%;">




    <div class="row">
        <!-- <div class="col-lg-4 ">
            <strong>Gajuwaka:</strong><br> 24-105-6/2 <br> Kanithi Road<br> Old Gajuwaka<br> Pincode-530026
            <br> Timings:10:00AM to 1:00PM <br> 3:00PM to 6:00PM

        </div> -->
        <div class="col-lg-4 ">
            <strong>   Visakhapatnam City</strong> <br> GVMC Zonel Office-2 <br> Shop No:11 & 12 <br> Ramatalkies Road <br> OPP:Kamal watches <br> Pincode: 530016 <br> Timings:10:00AM to 1:00PM
        </div>

        <div class="col-lg-4 ">
            <strong> Vijayawada City</strong><br> MIG - 2<br> Block No-3<br> Flat No-1<br> New HB Colony<br> Bhavani puram<br> Pincode-520012
            <br> Timings:10:00AM to 8:00PM
        </div>
    </div>
    <hr>
    <div class="row">

        <div class="col-lg-4 ">
            <strong>Machilipatnam City</strong><br> #21/527-13
            <br> Bhaskarapuram
            <br> Beside Nirmala High School<br> SP Bangalow Road<br> Pincode-521001
            <br> Timings:10:00AM to 5:00PM
        </div>
        <div class="col-lg-4">
            <strong> Hyderabad</strong><br> Boduppal
            <br> Hyderabad
            <br> Pincode-500076,
            <br> Timings:10:00AM to 8:00PM
        </div>
        <div class="col-lg-4">
            <strong>  USA</strong><br> 1209 Red Tailed Dr. <br>Flower Mound, <br>TX 75028, USA, <br><img src="assets/image/US-United-States-Flag-icon.png" alt=""> (+1)-848-459-5328
        </div>
    </div>
</div>