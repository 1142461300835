import { Component,   OnInit } from '@angular/core';
import { EcommerceService } from '../ecommerce.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrackingOrderPurchaseComponent } from '../tracking-order-purchase/tracking-order-purchase.component';
import { OrdersDetailsPopupComponent } from '../orders-details-popup/orders-details-popup.component';
import { InvoiceComponent } from '../invoice/invoice.component';

export interface DialogData {
  data: '';
}
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  ordersList: any;

  constructor(private service: EcommerceService, public dialog: MatDialog,  ) { }

  ngOnInit(): void {
    this.service.getOrdersdta(sessionStorage.getItem('RegUser')).subscribe((res: any) => {
    //  console.log(res, 'Gana Orders');
      this.ordersList = res.data;
    });
  }
  openOrderTracking(orderid) {
       this.dialog.open(TrackingOrderPurchaseComponent, {
        data: {
          height: '400px',
          width: '600px',
          data: orderid,
        },
      });
  }
  openOrderDetails(orderid) {
       this.dialog.open(OrdersDetailsPopupComponent, {
        data: {
          height: '400px',
          width: '600px',
          data: orderid,
        },
      });
  }
  openInvoice(orderid) {
    setTimeout(() => {
      this.dialog.open(InvoiceComponent, {
        data: {
          height: '100%',
          width: '100%',
          data: orderid,
        },
      });
    }, 200);

  }
}
