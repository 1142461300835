<div style="padding: 15px">
  <h6>Shipping Address</h6>
  <br />
  <section class="section-tb-padding">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="checkout-area">
            <div class="billing-area">
              <form [formGroup]="checkoutForm">
                <h2>Update Billing and Shipping Details</h2>
                <div class="billing-form">
                  <ul class="billing-ul input-2">
                    <li class="billing-li">
                      <label>Student Name</label>
                      <input
                        type="text"
                        formControlName="first_name"
                        placeholder="Student Name"
                        [ngClass]="{
                          'invalid-input':
                            this.checkoutForm.controls['first_name'].invalid &&
                            (this.checkoutForm.controls['first_name'].dirty ||
                              this.checkoutForm.controls['first_name'].touched)
                        }"
                      />
                    </li>
                    <li class="billing-li">
                      <label>Section</label>
                      <input
                        type="text"
                        formControlName="last_name"
                        placeholder="Section"
                        [ngClass]="{
                          'invalid-input':
                            this.checkoutForm.controls['last_name'].invalid &&
                            (this.checkoutForm.controls['last_name'].dirty ||
                              this.checkoutForm.controls['last_name'].touched)
                        }"
                      />
                    </li>
                  </ul>

                  <ul class="billing-ul">
                    <li class="billing-li">
                      <label>Door No / Flat No</label>
                      <input
                        type="text"
                        formControlName="address1"
                        placeholder=""
                        [ngClass]="{
                          'invalid-input':
                            this.checkoutForm.controls['address1'].invalid &&
                            (this.checkoutForm.controls['address1'].dirty ||
                              this.checkoutForm.controls['address1'].touched)
                        }"
                      />
                    </li>
                  </ul>
                  <ul class="billing-ul">
                    <li class="billing-li">
                      <label>Address and Landmark</label>
                      <input
                        type="text"
                        formControlName="address2"
                        placeholder="Street address"
                        [ngClass]="{
                          'invalid-input':
                            this.checkoutForm.controls['address2'].invalid &&
                            (this.checkoutForm.controls['address2'].dirty ||
                              this.checkoutForm.controls['address2'].touched)
                        }"
                      />
                    </li>
                  </ul>
                  <ul class="billing-ul">
                    <li class="billing-li">
                      <label>Town / City</label>
                      <input
                        type="text"
                        formControlName="city"
                        placeholder=""
                        [ngClass]="{
                          'invalid-input':
                            this.checkoutForm.controls['state'].invalid &&
                            (this.checkoutForm.controls['state'].dirty ||
                              this.checkoutForm.controls['state'].touched)
                        }"
                      />
                    </li>
                  </ul>
                  <ul class="billing-ul">
                    <li class="billing-li">
                      <mat-form-field
                        class="example-full-width"
                        appearance="fill"
                      >
                        <mat-label>State</mat-label>
                        <input
                          type="text"
                          matInput
                          formControlName="state"
                          [matAutocomplete]="auto"
                          [ngClass]="{
                            'invalid-input':
                              this.checkoutForm.controls['state'].invalid &&
                              (this.checkoutForm.controls['state'].dirty ||
                                this.checkoutForm.controls['state'].touched)
                          }"
                        />
                        <mat-autocomplete
                          #auto="matAutocomplete"
                          (optionSelected)="stateonSelect($event.option.value)"
                        >
                          <mat-option
                            *ngFor="let state of stateslist"
                            [value]="state"
                          >
                            {{ state }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      <!-- <label>State</label>
                                            <select formControlName="state" placeholder="" [ngClass]="{'invalid-input': this.checkoutForm.controls['state'].invalid 
                                            && (this.checkoutForm.controls['state'].dirty || this.checkoutForm.controls['state'].touched)}">
                                        <option value="Telangana">Telangana</option>
                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                        <option value="Maharashtra">Maharashtra</option>
                                        <option value="Odisha">Odisha</option>
                                        <option value="Tamil Nadu">Tamil Nadu</option>
                                        <option value="Karnataka">Karnataka</option>
                                        <option value="Kerala">Kerala</option>
                                        <option value="Rajasthan">Rajasthan</option>
                                        </select> -->
                      <!-- <input type="text" > -->
                    </li>
                    <li class="billing-li">
                      <label>Country</label>
                      <input type="text" formControlName="country" readonly />
                    </li>
                  </ul>
                  <ul class="billing-ul">
                    <li class="billing-li">
                      <label>Postcode / ZIP</label>
                      <input
                        pattern="[1-9]{1}[0-9]{5}"
                        type="text"
                        maxlength="6"
                        formControlName="zipcode"
                        placeholder=""
                        (keypress)="keyPressNumbers($event)"
                        [ngClass]="{
                          'invalid-input':
                            this.checkoutForm.controls['zipcode'].invalid &&
                            (this.checkoutForm.controls['zipcode'].dirty ||
                              this.checkoutForm.controls['zipcode'].touched)
                        }"
                      />
                    </li>
                    <li class="billing-li">
                      <label>Email address</label>
                      <input
                        type="email"
                        formControlName="emailid"
                        placeholder="Email address"
                        ngModel
                        [email]="true"
                        [ngClass]="{
                          'invalid-input':
                            this.checkoutForm.controls['emailid'].invalid &&
                            (this.checkoutForm.controls['emailid'].dirty ||
                              this.checkoutForm.controls['emailid'].touched)
                        }"
                      />
                    </li>
                    <li class="billing-li">
                      <label>Alternative Mobile</label>

                      <input
                        pattern="[1-9]{1}[0-9]{9}"
                        type="text"
                        maxlength="10"
                        placeholder="Mobile"
                        (keypress)="keyPressNumbers($event)"
                        formControlName="altno"
                        (input)="numberChange1($event)"
                        placeholder="Alternate Mobile"
                        [ngClass]="{
                          'invalid-input':
                            this.checkoutForm.controls['altno'].invalid &&
                            (this.checkoutForm.controls['altno'].dirty ||
                              this.checkoutForm.controls['altno'].touched)
                        }"
                      />
                    </li>
                  </ul>
                  <ul class="billing-ul input-2">
                    <li class="comment-area">
                      <label>Order notes(Optional)</label>
                      <textarea
                        formControlName="notes"
                        rows="4"
                        cols="80"
                      ></textarea>
                    </li>
                  </ul>
                </div>
              </form>
              <div class="checkout-btn">
                <button
                  type="submit"
                  [disabled]="this.checkoutForm.invalid"
                  class="btn btn-style1"
                  (click)="UpdateAddress()"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
